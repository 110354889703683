import App from 'layouts/App/App.jsx'

const indexRoutes = [
    {
        path: '/',
        component: App,
    },
]

export default indexRoutes
